export enum VoteResult {
  EntityIsBusy = 'EntityIsBusy',
  Success = 'Success',
  EntityCannotSpawn = 'EntityCannotSpawn',
  NotEnoughResources = 'NotEnoughResources',
  SpawnSpotNotEmpty = 'SpawnSpotNotEmpty',
  MoveSpotNotEmpty = 'MoveSpotNotEmpty',
  AttackFactionMismatch = 'AttackFactionMismatch',
  NoEntityToAttack = 'NoEntityToAttack',
  NoResourceToMine = 'NoResourceToMine',
  PathOutOfRange = 'PathOutOfRange',
  PathIsZero = 'PathIsZero',
  ToHexNotFound = 'ToHexNotFound',
  FromHexNotFound = 'FromHexNotFound',
  FactionMismatch = 'FactionMismatch',
  EntityNotFound = 'EntityNotFound',
  Error = 'Error',
}
